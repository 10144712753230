import React, {useEffect, useState} from "react";
import styles from "../classes/banner.module.css";
import xx from "../img/@2x.png";
import percents from "../img/percents.png";
import apple from "../img/apple.png";
import googlePlay from "../img/google-play@2x.png";
import close from "../img/close@2x.png";
import {checkUrl, isMobile} from "../utils/banner.utils";
import {useLocalStorage} from "usehooks-ts";

const BannerComponent = () => {
    const [animation, setAnimation] = useState('open')
    const [openBanner, setOpenBanner] = useState(false);
    const [value, setValue, removeValue] = useLocalStorage('banner-opened', false);
    const [bannerClosed, setBannerClosed] = useLocalStorage('banner-closed', false);
    useEffect(() => {
        if(!isMobile() && !checkUrl() && !bannerClosed) {
            setTimeout(() => showBanner(0), 1000);
        }
    }, []);
    const showBanner = async (ms) => {
        setAnimation(styles.banner_open);
        await new Promise(r => setTimeout(r, ms))
        setOpenBanner(true);
        setValue(true);
    }
    const hideBanner = async (ms) => {
        setAnimation(styles.banner_close);
        await new Promise(r => setTimeout(r, ms))
        setOpenBanner(false);
        setValue(false);
        setBannerClosed(true);
    }

    return (
        <div>
            {openBanner
                ? <div className={`${animation}`}>
                    <div className={styles.div}>
                        <div className={styles.div1}>
                            <div className={styles.div2}>
                                <div className={styles.child}/>
                                <div className={styles.googlePlayAppStore}>
                                    <a className={styles.link} href='https://apps.apple.com/us/app/%D1%82%D0%B0%D0%BA%D1%81%D1%96-%D1%81%D1%96%D0%BC%D0%BA%D0%B0-%D0%B2%D0%B0%D1%81%D0%B8%D0%BB%D1%8C%D0%BA%D1%96%D0%B2/id6446292238'
                                       rel='nofollow'>
                                        <div className={styles.appStore}>
                                            <div className={styles.appStoreChild}/>
                                            <div className={styles.appStore1}>
                                                <b className={styles.appStore2}>App Store</b>
                                                <img className={styles.icon} alt="" src={apple}/>
                                            </div>
                                        </div>
                                    </a>
                                    <div className={styles.goolePlay}>
                                        <div className={styles.appStoreChild}/>
                                        <a className={styles.link} href='https://play.google.com/store/apps/details?id=ua.taxisimka'
                                           rel='nofollow'>
                                            <div className={styles.googlePlay}>
                                                <b className={styles.googlePlay1}>Google play</b>
                                                <img
                                                    className={styles.googlePlayIcon}
                                                    alt=""
                                                    src={googlePlay}
                                                />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <img className={styles.icon1} alt="" src={percents}/>
                                <div className={styles.div3}>
                                    <b className={styles.b}>
                                        ЗАМОВЛЯЙ АВТО З ДОДАТКУ І МИ ПОВЕРНЕМО ТОБІ ЧАСТИНУ ГРОШЕЙ
                                    </b>
                                    <div className={styles.div4}>
                                        <p className={styles.p}>
                                            <span className={styles.span}>{`КЕШБЕК ДО `}</span>
                                            <span className={styles.span1}>100%</span>
                                            <span>
                  <span>{` `}</span>
                </span>
                                        </p>
                                        <p className={styles.p1}>
                <span>
                  <span>НА НАСТУПНІ ПОЇЗДКИ</span>
                </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <img onClick={() => hideBanner(1000)}
                                 className={styles.closeIcon}
                                 alt=""
                                 src={close}
                            />
                            <img className={styles.icon2} alt="" src={xx}/>
                        </div>
                    </div>
                </div>
                : null
            }
        </div>
    );
};

export default BannerComponent;

import i18n from "i18next";
import '../classes/App.css'

export let last = []


const element = (tag, classes = [], content, types, ids) => {
  const node = document.createElement(tag)

  if (classes.length) {
    node.classList.add(...classes)
  }

  if (content) {
    node.textContent = content
  }

  if (types) {
    node.setAttribute('type', types)
  }

  if (ids) {
    node.setAttribute('id', ids)
  }

  return node
}

function noop() {
}

export function upload(selector, options = {}) {
  let files = [],
    index = 0;
  const onUpload = options.onUpload ?? noop
  const input = document.querySelector(selector)
  const preview = element('div', ['preview'])

  const open = document.createElement('Button')
  open.setAttribute('type', 'button')
  open.textContent = i18n.t('upload_js_choise')
  open.className = "primaryNavButton button";
  const upload = element('Button', ['displayNone'], "Загрузить", 'button', 'buttonForPhoto')

  if (input) {
    if (options.multi) {
      input.setAttribute('multiple', true)
    }

    if (options.require) {
      input.setAttribute('required', false)
    }

    if (options.accept && Array.isArray(options.accept)) {
      input.setAttribute('accept', options.accept.join(','))
    }

    input.insertAdjacentElement('afterend', preview)
    input.insertAdjacentElement('afterend', upload)
    input.insertAdjacentElement('afterend', open)
  }

  const triggerInput = () => input.click()

  const changeHandler = (event) => {
    if (!event.target.files.length) {
      return
    }

    files = (Array.from(event.target.files))

    for (let i = files.length - 1; i >= 0; i--) {
      const myFile = new File([files[i]], `image${index}`, {type: files[i].type, lastModified: files[i].lastModified})
      last.push(myFile);
      index = last.length
    }

    files.forEach(file => {
      if (!file.type.match('image')) {
        return
      }

      const reader = new FileReader()

      reader.onload = ev => {
        const src = ev.target.result
        preview.insertAdjacentHTML('afterbegin', `
                <div class="preview-image">
                <div class="preview-remove" data-name="${file.name}">&times;</div>
                    <img src='${src}' alt='${file.name}'/>
                    <div class="preview-info">
                    <p>${index}. ${file.name}</p>
                    <div class="preview-info-progress"><div>
                    </div>
                </div>
                `)
      }

      reader.readAsDataURL(file)

    })
    input.value = []
  }

  const removeHandler = event => {
    if (!event.target.dataset.name) {
      return
    }

    const {name} = event.target.dataset
    files = files.filter(file => file.name !== name)
    last = last.filter(last => last.name !== name)
    const block = preview
      .querySelector(`[data-name="${name}"]`)
      .closest('.preview-image')
    block.remove()
  }


  const uploadHandler = () => {
    const previewInfo = preview.querySelectorAll('.preview-info')
    onUpload(last, previewInfo)
  }

  open.addEventListener('click', triggerInput)
  input?.addEventListener('change', changeHandler)
  preview.addEventListener('click', removeHandler)
  upload.addEventListener('click', uploadHandler)

}

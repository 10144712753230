import React from 'react';
import classesVacancies from "../classes/Vacancies.module.css";
import {Button, Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const Vacancies = () => {
    const {t} = useTranslation()
    const {lang} = useParams();

    return (
        <div className={classesVacancies.bg}>
            <div className="container text-center">
                <div className='text-center p-0 pt-5 mb-0'>
                    <p>{t('vacancies_title')}
                    </p>
                </div>
                <div className={classesVacancies.list}>
                    <div className="d-grid gap-2 col-9 mx-auto">
                        <br/>
                        <Nav.Link href={`/driver-own-car${lang === 'ru' ? '/ru' : ''}`}>
                            <Button className="primaryNavButton button fullWidth" variant="primary"
                                    size="lg">{t('vacancies_driver_own_car')}</Button>
                        </Nav.Link>
                        <br/>
                        <Nav.Link href={`/driver-company-car${lang === 'ru' ? '/ru' : ''}`}>
                            <Button className="primaryNavButton button fullWidth" variant="primary"
                                    size="lg">{t('vacancies_driver_company_car')}</Button>
                        </Nav.Link>
                        <br/>
                        <Nav.Link href={`/operator-vacancy${lang === 'ru' ? '/ru' : ''}`}>
                            <Button className="primaryNavButton button fullWidth" variant="primary"
                                    size="lg">{t('vacancies_operator')}</Button>
                        </Nav.Link>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Vacancies;

import React from 'react';
import classes from "../classes/Drivers.module.css";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import PhoneLinkToCall from "../components/PhoneLinkToCall";

const OperatorVacancy = () => {
    const {t} = useTranslation()

    return (
        <div className={classes.bg}>
            <div className="container text-center">
                <div className='text-center p-0 pt-5 mb-0'>
                </div>
                <div className={classes.list} style={{marginTop: '0px'}}>
                    <dt>{t('drivers_requirements')}</dt>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_requirement_1')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_requirement_2')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_requirement_3')}</dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_requirement_4')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_requirement_5')} </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_requirement_6')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_requirement_7')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_requirement_8')}
                    </dd>
                    <dd><i className="bi bi-check" style={{
                        color: 'lime',
                        fontSize: '25px',
                        marginRight: '10px'
                    }}></i> {t('drivers_communicability')} </dd>
                    <dd><i className="bi bi-check"
                           style={{color: 'lime', fontSize: '25px', marginRight: '10px'}}></i> {t('drivers_collective')}
                    </dd>
                </div>
                <div className={classes.list}>
                    <dt>{t('drivers_conditions')}</dt>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_condition_1')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_condition_2')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_condition_3')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_condition_4')}
                    </dd>
                    <dd><i className="bi bi-check"
                           style={{
                               color: 'lime',
                               fontSize: '25px',
                               marginRight: '10px'
                           }}></i> {t('operator_condition_5')}
                    </dd>
                </div>
                <br/>
                <div className="containerWithBorder blue" style={{marginTop: '0px'}}>
                    <h2>{t('interview')}</h2>
                    <p style={{marginTop: '22px', marginBottom: '0px'}}>{t('hr_manager')}</p>
                    <p style={{marginBottom: '5px'}}>{t('hr_manager_time')}</p>
                    <PhoneLinkToCall
                        phoneNumberTitle={"093 655 27 34"}
                        phone={"0936552734"}
                    ></PhoneLinkToCall>
                    <p style={{marginTop: '5px', marginBottom: '0px'}}>{t('boss')}</p>
                    <p style={{marginBottom: '5px'}}>{t('boss_time')}</p>
                    <PhoneLinkToCall
                        phoneNumberTitle={"063 720 80 50"}
                        phone={"0637208050"}
                    ></PhoneLinkToCall>
                </div>
            </div>
        </div>
    );
};

export default OperatorVacancy;

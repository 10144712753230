import React from "react";
const PhoneLinkToCall = (props) => {

    return (
        <div className="phoneNumber ph-num">
            <a className='link-light text-decoration-none fs-4' href={"tel: " + props.phone}>
                <p className='flex'
                   style={{
                       border: '2px solid black',
                       padding: '10px',
                       borderRadius: '42px',
                       color: 'white',
                       backgroundColor: 'black',
                       marginBottom: 0
                }}
                >
                    <i className="bi bi-telephone mx-1"></i>
                    {props.phoneNumberTitle}
                </p></a>
        </div>
    );
};

export default PhoneLinkToCall;

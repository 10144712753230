import React from 'react';
import '../classes/App.css'
import {useParams} from "react-router-dom";
import i18n from "i18next";

const ButtonVacancies = () => {
    return (
        <a href={`/vacancies${window.location.href.includes('/ru') ? '/ru' : ''}`} className="fixedVacancies" >
        </a>
    );
};

export default ButtonVacancies;

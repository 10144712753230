import React, {useEffect, useState} from "react";
import styles from "../classes/mobile-banner.module.css";
import xx from "../img/@2x.png";
import percents from "../img/percents.png";
import apple from "../img/apple.png";
import googlePlay from "../img/google-play@2x.png";
import close from "../img/close@2x.png";
import {checkUrl, isIOS, isMobile} from "../utils/banner.utils";
import { useLocalStorage } from 'usehooks-ts'

const MobileBannerComponent = () => {
    const [animation, setAnimation] = useState('open')
    const [openBanner, setOpenBanner] = useState(false);
    const [value, setValue, removeValue] = useLocalStorage('banner-opened', false);
    const [bannerClosed, setBannerClosed] = useLocalStorage('banner-closed', false);
    const googlePlayUrl = 'https://play.google.com/store/apps/details?id=ua.taxisimka';
    const appStoreUrl = 'https://apps.apple.com/us/app/%D1%82%D0%B0%D0%BA%D1%81%D1%96-%D1%81%D1%96%D0%BC%D0%BA%D0%B0-%D0%B2%D0%B0%D1%81%D0%B8%D0%BB%D1%8C%D0%BA%D1%96%D0%B2/id6446292238';
    useEffect(() => {
        if (isMobile() && !checkUrl() && !bannerClosed) {
            setTimeout(() => showBanner(0), 1000);
        }
    }, []);
    const showBanner = async (ms) => {
        setAnimation(styles.banner_open);
        await new Promise(r => setTimeout(r, ms))
        setOpenBanner(true);
        setValue(true);
    }
    const hideBanner = async (ms) => {
        setAnimation(styles.banner_close);
        await new Promise(r => setTimeout(r, ms))
        setOpenBanner(false);
        setValue(false);
        setBannerClosed(true);
    }

    return (
        <div>
            {openBanner
                ? <div className={`${animation}`}>
                    <div className={styles.div}>
                        <div className={styles.appStore}>
                            <div className={styles.div1}>
                                <div className={styles.child}/>
                                <a className={styles.link}
                                   href={isIOS()?appStoreUrl:googlePlayUrl}
                                   rel='nofollow'>
                                    <div className={styles.goolePlay}>
                                        <div className={styles.appStoreChild}/>
                                        <div className={styles.appStore2}>
                                            <b className={styles.appStore3}>{isIOS() ? "App Store" : "Google Play"}</b>
                                            <img
                                                className={styles.icon}
                                                alt=""
                                                src={isIOS() ? apple : googlePlay}
                                            />
                                        </div>
                                    </div>
                                </a>
                                <img className={styles.icon1} alt="" src={percents}/>
                                <div className={styles.div2}>
                                    <b className={styles.b}>
                                        ЗАМОВЛЯЙ АВТО З ДОДАТКУ І МИ ПОВЕРНЕМО ТОБІ ЧАСТИНУ ГРОШЕЙ
                                    </b>
                                    <div className={styles.div3}>
                                        <span>{`кешбек до `}</span>
                                        <span className={styles.span}>
                                            <span>100%</span>
                                        </span>
                                        <span>
                                            <span className={styles.span}>{` `}</span>
                                            <span>на наступнІ поЇздки</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <img onClick={() => hideBanner(1000)}
                                 className={styles.closeIcon}
                                 alt=""
                                 src={close}
                            />
                            <img className={styles.icon2} alt="" src={xx}/>
                        </div>
                    </div>
                </div>
                : null
            }
        </div>
    );
};

export default MobileBannerComponent;

import React, {Fragment, useRef, useState} from 'react';

import '../classes/App.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import {QUICK_PHONE_NUMBERS_MAPPING} from "../utils/consts";
import {Overlay, Popover} from "react-bootstrap";

const ButtonPhone = () => {
    const {navigation} = useParams()
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    let numbersPull = QUICK_PHONE_NUMBERS_MAPPING[navigation ? navigation : "default"];

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const handleHide = () => {
        setShow(false);
    };

    if (!numbersPull) {
        numbersPull = QUICK_PHONE_NUMBERS_MAPPING["default"];
    }
    return (
        <div ref={ref}>
            <div className="magic_call fixedPhone" onClick={handleClick}>
                <div className="magic-call-1">
                    <FontAwesomeIcon icon={faPhone} size="xs"/>
                </div>
                <div className="magic-call-2"></div>
            </div>

            <Overlay
                show={show}
                target={target}
                placement="left-start"
                container={ref}
                rootClose={true}
                rootCloseEvent="click"
                onHide={handleHide}
                containerPadding={10}
            >
                <Popover id="popover-contained" className="fixedPhones active">
                    <Popover.Body>
                        <>
                            {numbersPull.map((phoneRecord, i) => (
                                <a href={"tel: " + phoneRecord.tel} className='phones' key={i}>
                                    <p className='contact-single-phone flex'>
                                        <img className='d-inline-block'
                                             width='30px'
                                             height='30px'
                                             src={phoneRecord.icon}
                                             alt='logo'
                                        />
                                        <span style={{color: phoneRecord.color}}>{phoneRecord.label}</span>
                                    </p>
                                </a>
                            ))}
                        </>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
};

export default ButtonPhone;

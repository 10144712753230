import classes from '../../classes/City.module.css'
import google from "../../img/google_play.webp";
import appStore from "../../img/app_store.webp";
import bucha from '../../img/Vasilkiv.webp'
import life from "../../img/lifecell_logo.webp";
import kyivstar from "../../img/kyivstar_icon.webp";
import vodafone from "../../img/vodafone_icon.png";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import i18n from "../../i18n";
import {Button, Nav} from "react-bootstrap";
import React from "react";
import {useLocalStorage} from "usehooks-ts";

const REGION  = () => {
  const {t} = useTranslation();
  const {navigation} = useParams();
  const {lang} = useParams();
  const [value, setValue, removeValue] = useLocalStorage('banner-opened', false);
  const [bannerClosed, setBannerClosed] = useLocalStorage('banner-closed', false);
  return (
    <div className={value && !bannerClosed ? classes.bgImgVasBlur : classes.bgImgVas}>
      <div className={classes.textAboveImg}>
        <h1>{t('title_'+navigation)}</h1>
      </div>
      <div className={classes.allWithoutText}>
        <div className={classes.ohNo}><div className={classes.logo}/>
          <img src={bucha} className="img-fluid" alt={t('title_'+navigation)}/>
          <div className={classes.list}>
            <a href='tel: 0932237070' className={classes.linkS}><p
              className='contact-single-phone flex'>
              <img className='d-inline-block'
                   width='30px'
                   height='30px'
                   src={life}
                   alt='logo'
              />
              <span style={{color: 'rgb(255,255,255)'}}>093 223 70 70</span>
            </p></a>
            <a href='tel: 0982237070' className={classes.linkS}><p
              className='contact-single-phone flex'>
              <img className='d-inline-block'
                   width='30px'
                   height='30px'
                   src={kyivstar}
                   alt='logo'/>
              <span style={{color: 'rgb(255,255,255)'}}>098 223 70 70</span>
            </p></a>
            <a href='tel: 0952237070' className={classes.linkS}><p
              className='contact-single-phone flex'>
              <img className='d-inline-block'
                   width='30px'
                   height='30px'
                   src={vodafone}
                   alt='logo'/>
              <span style={{color: 'rgb(255,255,255)'}}>095 223 70 70</span>
            </p></a>
          </div>
        </div>
        <div className={classes.belowImage}>
          <div className={classes.test2}><h3>{t('city_h3')}</h3>
          <div className={classes.cashBack}>{t('cash_back')}</div>
          <div className={classes.imgGoogle}>
            <a href='https://play.google.com/store/apps/details?id=ua.taxisimka' rel='nofollow'>
              <img
                className='img-fluid'
                src={google}
                alt="google play link"/>
            </a>
            <a href='https://apps.apple.com/us/app/%D1%82%D0%B0%D0%BA%D1%81%D1%96-%D1%81%D1%96%D0%BC%D0%BA%D0%B0-%D0%B2%D0%B0%D1%81%D0%B8%D0%BB%D1%8C%D0%BA%D1%96%D0%B2/id6446292238' rel='nofollow'>
              <img
                className='img-fluid'
                src={appStore}
                alt="app store link"/>
              </a>
          </div></div>
          <p>{t('city_p')}</p>
          <div className={classes.last}></div>
        </div>
      </div>
      <div className={classes.region_block}>
        <div className={classes.region_title}>
          <h1>{t('region_' + navigation + '_title')}</h1>
        </div>
        <div className={classes.region_description}>
          <p>{t('region_' + navigation + '_description')}</p>
        </div>
        <div className={classes.links_for_customer_title}>
          <p>{t('links_for_customer_title')}</p>
        </div>
        <div className={classes.imgGoogle}>
          <a href='https://play.google.com/store/apps/details?id=ua.taxisimka' rel='nofollow'>
            <img
                className='img-fluid'
                src={google}
                alt="google play link"/>
          </a>
          <a href='https://apps.apple.com/us/app/%D1%82%D0%B0%D0%BA%D1%81%D1%96-%D1%81%D1%96%D0%BC%D0%BA%D0%B0-%D0%B2%D0%B0%D1%81%D0%B8%D0%BB%D1%8C%D0%BA%D1%96%D0%B2/id6446292238' rel='nofollow'>
            <img
                className='img-fluid'
                src={appStore}
                alt="app store link"/>
          </a>
        </div>
        <div className={classes.region_services}>
          <pre>{t('region_' + navigation + '_services')}</pre>
        </div>
      </div>
      <div className={classes.region_block}>
        <div className={classes.region_title}>
          <h1>{t('region_' + navigation + '_title_for_driver')}</h1>
        </div>
        <div className={classes.region_description}>
          <p>{t('region_' + navigation + '_description_for_driver')}</p>
        </div>
        <div className={classes.region_services}>
          <pre>{t('region_' + navigation + '_services_for_driver')}</pre>
        </div>
        <div className={classes.links_for_driver_title}>
          <p>{t('links_for_driver_title')}</p>
        </div>
        <div>
          <Nav.Link href={`/vacancies${lang === 'ru' ? '/ru' : ''}`}>
            <Button className='fs-3 p-3 mt-3'>{t('links_for_driver_button')}</Button>
          </Nav.Link>
        </div>
      </div>
    </div>

  );
};

export default REGION ;
